import { ChartOptions as ChartJSOptions } from "chart.js";

import { ChartOptions } from "./card-line-chart.models";

export const getChartOptions = ({
  showTooltip,
  scales,
  unit,
}: ChartOptions): ChartJSOptions<"line"> => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: showTooltip,
      callbacks: {
        label: unit
          ? (context) => `${context.dataset.label}: ${context.parsed.y} ${unit}`
          : undefined,
      },
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
  scales: {
    y: {
      grid: {
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
      border: {
        display: false,
      },
      ticks: {
        display: false,
      },
      min: scales?.y?.min,
      max: scales?.y?.max,
      suggestedMin: scales?.y?.suggestedMin,
      suggestedMax: scales?.y?.suggestedMax,
    },
    x: {
      grid: {
        display: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
      border: {
        display: false,
      },
      ticks: {
        display: false,
      },
      min: scales?.x?.min,
      max: scales?.x?.max,
      suggestedMin: scales?.x?.suggestedMin || 0,
      suggestedMax: scales?.x?.suggestedMax,
    },
  },
});
