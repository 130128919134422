import { useState } from "react";

import {
  ChangeFunction,
  DEFAULT_LIMIT,
  LimitFunction,
  SearchFunction,
} from "./select-search-paginated.model";
import { KeyValue } from "@/models";

export const useSelectSearchPaginated = (
  searchFunction: SearchFunction,
  limitFunction: LimitFunction,
  onChange: ChangeFunction
) => {
  const [search, setSearch] = useState<string>("");

  const handleOnChange = (_event: unknown, value: KeyValue | null) => {
    onChange(value);
  };

  const handleSearch = (_event: unknown, value: string) => {
    if (!value) return reset();
    setSearch(value);
    searchFunction(value);
    limitFunction(DEFAULT_LIMIT);
  };

  const handleLoadMore = (isLoading: boolean) => () => {
    if (isLoading) return false;

    searchFunction(search);
    limitFunction();

    return false;
  };

  const reset = () => {
    searchFunction("");
    setSearch("");
    limitFunction(DEFAULT_LIMIT);
  };

  return {
    handleOnChange,
    handleSearch,
    handleLoadMore,
    search,
  };
};
