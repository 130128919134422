import React from "react";

import { KeyValue } from "@/models";

interface OptionComponentProps {
  option: KeyValue;
  loading: boolean;
  liProps: React.HTMLAttributes<HTMLLIElement>;
  handleLoadMore: (isLoading: boolean) => () => boolean;
  loadMoreText: string;
  loadingText: string;
}

export const OptionComponent: React.FC<OptionComponentProps> = ({
  liProps,
  option,
  loading,
  handleLoadMore,
  loadMoreText,
  loadingText,
}) =>
  option.key === "load-more" ? (
    <li
      {...liProps}
      key={option.key}
      onClick={handleLoadMore(loading)}
      style={{
        fontWeight: "bold",
      }}
    >
      {loading ? loadingText : loadMoreText}
    </li>
  ) : (
    <li
      {...liProps}
      key={option.key}
      style={{
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
      }}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "inline-block",
          width: "100%",
        }}
      >
        {option.value}
      </span>
    </li>
  );
