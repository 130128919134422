import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment"; // Asegúrate de importar esto
import { Spanish } from "flatpickr/dist/l10n/es.js";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";

import { useInternationalization } from "../../contexts";
import MDInput, { MDInputProps } from "../md-input";

import "flatpickr/dist/flatpickr.css";

interface DatePickerComponentProps extends DateTimePickerProps {
  input?: MDInputProps;
  loading?: boolean;
}

export const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  input = {},
  loading,
  ...rest
}) => {
  const { currentLanguage } = useInternationalization();
  return (
    <Flatpickr
      {...rest}
      options={{
        ...rest.options,
        ...(currentLanguage === "es" ? { locale: Spanish } : {}),
      }}
      render={(_, ref) => (
        <MDInput
          {...input}
          inputRef={ref}
          allowErrors={false}
          disabled={loading}
          InputProps={{
            endAdornment: loading ? (
              <InputAdornment position="end">
                <CircularProgress size={20} color="primary" />
              </InputAdornment>
            ) : undefined,
          }}
        />
      )}
    />
  );
};
