import { SearchUsernamesDTO } from "../dtos";
import { SearchUsernames } from "@/models/user";

export const mapSearchUsernamesDTOtoSearchUsernames = (
  usernames: SearchUsernamesDTO["data"]
): SearchUsernames => {
  return {
    users: usernames.users.map((user) => ({
      _id: user._id,
      username: user.username,
    })),
    totalPages: usernames.totalPages,
    totalUsers: usernames.totalUsers,
  };
};
