import { SortDirection } from "@/models/pagination";

export interface SearchState<T, J> {
  filterStatus: Partial<T> | undefined;
  sortField: J;
  sortDirection: SortDirection;
  page: number;
  itemsPerPage: number;
}

export const DEBOUNCE_TIMEOUT: number = 300;

export const MAX_ITEMS_PER_PAGE = 25;
