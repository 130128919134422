import { useState, useEffect, useCallback } from "react";

import breakpoints from "../../styles/themes/common/breakpoints";

export const useIsMobile = (breakpoint: number = breakpoints.values.md): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= breakpoint);

  const handleWindowSizeChange = useCallback(() => {
    const isCurrentlyMobile = window.innerWidth <= breakpoint;

    if (isCurrentlyMobile !== isMobile) {
      setIsMobile(isCurrentlyMobile);
    }
  }, [isMobile, breakpoint]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  return isMobile;
};
