import React from "react";

import { Grid2 as Grid, Grid2Props } from "@mui/material";

interface GridProps extends Grid2Props {
  children: React.ReactNode;
}

export const GridComponent: React.FC<GridProps> = ({ children, ...props }) => {
  return <Grid {...props}>{children}</Grid>;
};

export default GridComponent;
