import React from "react";

import { StyledTitleIconCard, StyledTitleIconCardHeaderBox } from "./title-icon-card.styles";
import { SkeletonComponent } from "../skeleton";

interface TitleIconCardProps {
  children: React.ReactNode;
}

export const TitleIconCardPlaceholder: React.FC<TitleIconCardProps> = ({ children }) => {
  return (
    <StyledTitleIconCard>
      <StyledTitleIconCardHeaderBox>
        <SkeletonComponent height={40} width={40} variant="rounded" />
        <SkeletonComponent height={40} width={"100%"} variant="rounded" />
      </StyledTitleIconCardHeaderBox>
      {children}
    </StyledTitleIconCard>
  );
};
