import React from "react";

interface MaterialSymbolProps {
  symbol: string;
  fill: boolean;
}

export const MaterialSymbolComponent: React.FC<MaterialSymbolProps> = ({ symbol, fill }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontVariationSettings: `'FILL' ${fill ? 1 : 0}, 'wght' 400, 'GRAD' 0, 'opsz' 24`,
      }}
    >
      <span className="material-symbols-outlined">{symbol}</span>
    </div>
  );
};
