import React from "react";

import Icon from "@mui/material/Icon";

import { IconText } from "./icon-text-table-field.models";
import MDBox from "@/lib/components/md-box";
import MDTypography from "@/lib/components/md-typography";

interface IconTextTableFieldProps {
  enabledIcon: IconText;
  disabledIcon: IconText;
  isEnable: boolean;
  size?: "small" | "medium" | "large";
}

export const IconTextTableFieldComponent = ({
  enabledIcon,
  disabledIcon,
  isEnable,
  size = "small",
}: IconTextTableFieldProps) => {
  const color = isEnable ? "success" : "error";
  const icon = isEnable ? enabledIcon.name : disabledIcon.name;

  return (
    <MDBox display="flex" alignItems="center">
      <Icon color={color} fontSize={size} sx={{ fontWeight: "bold", mr: 1 }}>
        {icon}
      </Icon>
      <MDTypography variant="caption" fontWeight={"bold"} color="text" sx={{ lineHeight: 0 }}>
        {isEnable ? enabledIcon.text : disabledIcon.text}
      </MDTypography>
    </MDBox>
  );
};
