import React, { ReactNode } from "react";

import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";
import { Column } from "@tanstack/react-table";

import { useMaterialUIController } from "../../../../../contexts";
import MDBox from "../../../../md-box";

interface DataTableHeadCellProps<T> {
  column: Column<T, unknown>;
  onClick?: () => void;
  children: ReactNode;
}

export function DataTableHeadCellComponent<T>({
  onClick,
  column,
  children,
  ...rest
}: DataTableHeadCellProps<T>): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const width = column.getSize() ? column.getSize() : "auto";
  const minWidth = column.columnDef.minSize ?? column.getSize();
  const maxWidth = column.columnDef.maxSize;
  const align = column.columnDef.meta?.align ?? "left";
  const sorted = column.getCanSort() ? column.getIsSorted() : false;

  return (
    <MDBox
      component="th"
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      py={1.5}
      px={2}
      sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        {...rest}
        onClick={onClick}
        position="relative"
        textAlign={align}
        color={darkMode ? "white" : "secondary"}
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold } }: Theme) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          textTransform: "uppercase",
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
        })}
        dataTestId={`data-table-column-${column.id.toLowerCase()}`}
      >
        {children}
        {sorted && (
          <MDBox
            position="absolute"
            top={0}
            right={align !== "right" ? "16px" : 0}
            left={align === "right" ? "-5px" : "unset"}
            sx={({ typography: { size } }: Theme) => ({
              fontSize: size.lg,
            })}
          >
            <MDBox
              position="absolute"
              top={-6}
              color={sorted === "asc" ? "text" : "secondary"}
              opacity={sorted === "asc" ? 1 : 0.5}
            >
              <Icon>arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={0}
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon>arrow_drop_down</Icon>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}
