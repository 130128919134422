import { UPDATE_USER_PASSWORD } from "./api-url.constants";
import { UpdateUserPasswordApiDto } from "./dtos";
import { API_METHODS } from "../shared";
import { InvalidCurrentPasswordError } from "./errors/InvalidCurrentPasswordError";
import { UpdateUserError } from "./errors/UpdateUserError";
import { typedFetch } from "@/lib/helpers";

export const updateUserPassword = async (user: UpdateUserPasswordApiDto): Promise<boolean> => {
  const result = await typedFetch(`${UPDATE_USER_PASSWORD}/${user._id}`, {
    method: API_METHODS.PUT,
    body: JSON.stringify({ password: user.password, currentPassword: user.currentPassword }),
  });

  if (result.status === 400) throw new InvalidCurrentPasswordError();

  if (!result.ok) {
    throw new UpdateUserError();
  }

  return true;
};
