import { GET_USERNAMES } from "./api-url.constants";
import { SearchUsernamesDTO } from "./dtos";
import { mapSearchUsernamesDTOtoSearchUsernames } from "./mappers";
import { jsonFetch } from "@/lib/helpers";
import { SearchUsernames } from "@/models/user";

export const getSearchUsernames = async (queryParams: string): Promise<SearchUsernames> => {
  const searchUsernamesDTO = await jsonFetch<SearchUsernamesDTO>(GET_USERNAMES + queryParams);
  return mapSearchUsernamesDTOtoSearchUsernames(searchUsernamesDTO.data);
};
