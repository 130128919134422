/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The gradientChartLine() function helps you to create a gradient color for the chart line
 */

// Material Dashboard 2 PRO React TS Helper Functions
import rgba from "./rgba";

/**
 * Creates a linear gradient color for a chart line.
 *
 * @param chart - The canvas element where the gradient will be applied.
 * @param color - The base color for the gradient, in hexadecimal or named color format.
 * @param opacity - The opacity level for the base color in the gradient (default is 0.2).
 * @param startY - The starting Y position for the gradient (default is 230).
 * @param endY - The ending Y position for the gradient (default is 50).
 * @returns A linear gradient object of type CanvasGradient, or undefined if the canvas context is not available.
 */
function gradientChartLine(
  chart: HTMLCanvasElement,
  color: string,
  opacity: number = 0.2,
  startY: number = 230,
  endY: number = 50
): CanvasGradient | undefined {
  // Get the 2D rendering context from the canvas element
  const ctx = chart.getContext("2d");

  // Create a linear gradient with specified start and end points
  const gradientStroke = ctx?.createLinearGradient(0, startY, 0, endY);

  if (gradientStroke) {
    // Convert the base color to an RGBA color with the given opacity
    const primaryColor = rgba(color, opacity).toString();

    // Add color stops to define the gradient transition
    gradientStroke.addColorStop(1, primaryColor);
    gradientStroke.addColorStop(0.2, "rgba(72, 72, 176, 0.0)");
    gradientStroke.addColorStop(0, "rgba(203, 12, 159, 0)");
  }

  // Return the created gradient
  return gradientStroke;
}

export default gradientChartLine;
