import React from "react";

import {
  StyledTitleIconCard,
  StyledTitleIconCardHeaderBox,
  StyledTitleIconCardHeaderIconBox,
  StyledTitleIconCardHeaderRightAdornmentBox,
  StyledTitleIconCardHeaderTypography,
} from "./title-icon-card.styles";
import { MaterialSymbolComponent } from "../material-symbol";

interface TitleIconCardProps {
  title: string;
  icon: string;
  fillIcon?: boolean;
  rightAdornment?: React.ReactNode;
  children: React.ReactNode;
}

export const TitleIconCardComponent: React.FC<TitleIconCardProps> = ({
  title,
  icon,
  fillIcon = false,
  children,
  rightAdornment,
}) => {
  return (
    <StyledTitleIconCard>
      <StyledTitleIconCardHeaderBox>
        <StyledTitleIconCardHeaderIconBox>
          <MaterialSymbolComponent symbol={icon} fill={fillIcon} />
        </StyledTitleIconCardHeaderIconBox>
        <StyledTitleIconCardHeaderTypography variant="h6" fontWeight="medium">
          {title}
        </StyledTitleIconCardHeaderTypography>
        {rightAdornment && (
          <StyledTitleIconCardHeaderRightAdornmentBox>
            {rightAdornment}
          </StyledTitleIconCardHeaderRightAdornmentBox>
        )}
      </StyledTitleIconCardHeaderBox>
      {children}
    </StyledTitleIconCard>
  );
};
