import { useCallback, useEffect, useState } from "react";

export const useForm = <T>(
  initialForm: T,
  initialFormError: Record<keyof T, string>,
  validatorConfig: {
    [key in keyof Partial<T>]: {
      predicate: (value: T[key]) => boolean;
      error: string;
    }[];
  },
  validationOnChange = true
) => {
  const [form, setForm] = useState(initialForm);
  const [formErrors, setFormErrors] = useState(initialFormError);

  useEffect(() => {
    setForm(initialForm);
    setFormErrors(initialFormError);
  }, [initialForm, initialFormError]);

  const isFormValid = (): boolean => {
    return Object.values(formErrors).every((error) => error === "");
  };

  const getError = (
    field: keyof T,
    currentValue: T[keyof T],
    { skipValidation } = { skipValidation: false }
  ) => {
    if (skipValidation) return "";
    const fieldConfig = validatorConfig[field];
    const error = fieldConfig?.find(({ predicate }) => predicate(currentValue));
    return error ? error.error : "";
  };

  const getFormValidationErrors = (values: T) => {
    return Object.keys(initialFormError).reduce(
      (errors, field) => {
        errors[field as keyof T] = getError(field as keyof T, values[field as keyof T]);
        return errors;
      },
      {} as Record<keyof T, string>
    );
  };

  const handleChange = (field: keyof T) => (value: T[keyof T]) => {
    const newValues = { ...form, [field]: value };
    setForm(newValues);

    if (validationOnChange) {
      const error = getFormValidationErrors(newValues);
      setFormErrors({
        ...formErrors,
        [field]: error[field],
      });
    }
  };

  const handleSetValues = (form: T) => {
    setForm(form);
  };

  const validateForm = (form: T) => {
    const trimValues = trimFormValues(form);
    setForm(trimValues);
    const errors = getFormValidationErrors(trimValues);
    setFormErrors(errors);

    const isValid = Object.values(errors).every((error) => error === "");
    return isValid;
  };

  const trimFormValues = useCallback(
    (form: T) => {
      const trimmedForm = Object.keys(form as object).reduce((acc, key) => {
        const typedKey = key as keyof T;
        const value = form[typedKey];

        // Only trim string values
        acc[typedKey] = typeof value === "string" ? (value.trim() as T[keyof T]) : value;
        return acc;
      }, {} as T);

      return trimmedForm;
    },
    [form]
  );

  const resetFormErrors = (errors: Record<keyof T, string>) => {
    setFormErrors({ ...formErrors, ...errors });
  };

  const hasChanges = JSON.stringify(initialForm) !== JSON.stringify(form);

  return {
    form,
    formErrors,
    setFormErrors,
    handleChange,
    isFormValid,
    hasChanges,
    validateForm,
    handleSetValues,
    resetFormErrors,
  };
};

export default useForm;
