import { getCustomerServiceContractsByUserIdUrl } from "./api-url.constants";
import { CustomerServiceContractsDTO } from "./dtos";
import { mapCustomerServiceContractsDTOtoCustomerServiceContracts } from "./mappers";
import { ServiceContract } from "../../../models";
import { jsonFetch } from "@/lib/helpers";

export const getCustomerServiceContractsByUserId = async (
  userId: string
): Promise<ServiceContract[]> => {
  const customerServiceContractDTO = await jsonFetch<CustomerServiceContractsDTO>(
    getCustomerServiceContractsByUserIdUrl(userId)
  );
  return mapCustomerServiceContractsDTOtoCustomerServiceContracts(customerServiceContractDTO.data);
};
