import React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import {
  //  useMaterialUIController,
  AuthenticationProvider,
  NotificationProvider,
  AlertDialogProvider,
  InternationalizationProvider,
  MaterialUIControllerProvider,
  BreadcrumbTitleProvider,
} from "./lib/contexts";
import { RouterComponent } from "./routes";
import Themes, { getTheme } from "./styles/themes";

export const AppDependencies: React.FC<
  React.PropsWithChildren<{
    queryClient: QueryClient;
  }>
> = ({ children, queryClient }) => {
  // TODO: Implement dark mode
  // const [darkMode, setDarkMode] = React.useState<boolean>(
  //   window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
  // );

  // useEffect(() => {
  //   setDarkMode(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches);
  // }, [window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches]);

  // const themeName = getTheme(darkMode, false);

  const themeName = getTheme(false, false);

  return (
    <MaterialUIControllerProvider>
      <ThemeProvider theme={Themes[themeName]}>
        <CssBaseline />
        <InternationalizationProvider>
          <AlertDialogProvider>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <AuthenticationProvider>
                  <BreadcrumbTitleProvider>{children}</BreadcrumbTitleProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </AuthenticationProvider>
              </QueryClientProvider>
            </NotificationProvider>
          </AlertDialogProvider>
        </InternationalizationProvider>
      </ThemeProvider>
    </MaterialUIControllerProvider>
  );
};

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: 0,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <AppDependencies queryClient={queryClient}>
      <RouterComponent />
    </AppDependencies>
  );
};

export default App;
