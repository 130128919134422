import React, { ChangeEvent } from "react";

import { SxProps } from "@mui/material";
import TextField from "@mui/material/TextField";

interface TextAreaComponent {
  label?: string;
  rows?: number;
  onChange: (text: string) => void;
  variant?: "outlined" | "filled" | "standard";
  value: string;
  fullWidth?: boolean;
  sx?: SxProps;
  placeholder?: string;
}

export const TextAreaComponent: React.FC<TextAreaComponent> = ({
  label,
  rows = 4,
  onChange,
  variant = "outlined",
  value,
  fullWidth = true,
  sx,
  placeholder,
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };
  return (
    <TextField
      multiline
      rows={rows}
      variant={variant}
      label={label}
      onChange={handleChange}
      value={value}
      fullWidth={fullWidth}
      sx={sx}
      inputProps={{
        maxLength: 1000,
      }}
      placeholder={placeholder}
    />
  );
};
