import React, { useMemo } from "react";

import { useTheme } from "@mui/material";
import { Bar } from "react-chartjs-2";

import MDBox from "../md-box";
import { barChartOptions } from "./bar-chart-options";
import { BarChartComponentProps, ChartData } from "./bar-chart.models";
import MDTypography from "../md-typography";

export const BarChartComponent: React.FC<BarChartComponentProps> = ({
  style: { height = "15rem", maxBarThickness = 24 } = {},
  barChartData: { labels, data, backgroundColor, emptyDataMessage },
  tooltip,
}) => {
  const theme = useTheme();

  const chartData = useMemo<ChartData>(
    () => ({
      labels,
      datasets: [
        {
          data,
          backgroundColor,
          borderWidth: 0,
          borderRadius: 2,
          maxBarThickness,
          hoverBackgroundColor: theme.palette.barChartBarHover.main,
        },
      ],
    }),
    [labels, data, backgroundColor, maxBarThickness, theme]
  );

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={height}
      width="100%"
    >
      {chartData.datasets[0].data.length === 0 ? (
        <MDTypography variant="body2" textAlign="center">
          {emptyDataMessage}
        </MDTypography>
      ) : (
        <Bar data={chartData} options={barChartOptions(theme, tooltip)} />
      )}
    </MDBox>
  );
};
