import { Theme } from "@mui/material";
import { ChartOptions as ChartJSOptions, TooltipItem } from "chart.js";

import { BarChartTooltipProps } from "./bar-chart.models";

export const barChartOptions = (
  theme: Theme,
  tooltip?: BarChartTooltipProps
): ChartJSOptions<"bar"> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: tooltip
        ? {
            enabled: true,
            displayColors: false,
            padding: 10,
            boxPadding: 0,
            titleColor: tooltip.titleColor,
            backgroundColor: tooltip.backgroundColor,
            titleMarginBottom: 0,
            callbacks: {
              title: (tooltipItems: TooltipItem<"bar">[]) =>
                tooltip.titleCallback(tooltipItems[0].dataIndex),
              label: () => "",
            },
          }
        : undefined,
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 120,
        display: true,
        border: {
          display: false,
        },
        grid: {
          display: true,
          color: theme.palette.barChartGridColor.light,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        border: {
          display: false,
        },
        grid: {
          display: true,
          color: theme.palette.barChartGridColor.light,
        },
        ticks: {
          font: {
            weight: (context) => {
              const labelsLength = context.chart.data.labels?.length;
              if (!labelsLength) return "normal";

              const lastIndex = labelsLength - 1;
              return context.index === lastIndex ? "bold" : "normal";
            },
          },
        },
      },
    },
  };
};
