import { ChartDataset } from "chart.js";

import { NoiseLevels } from "@/models/booking-details";

const getFilteredNoiseLevels = (
  noiseLevels: NoiseLevels[] = [],
  maxRange?: number
): NoiseLevels[] => {
  if (!maxRange) return noiseLevels;
  const timeNow = Date.now();
  return noiseLevels.filter((noise) => noise.createdAt >= timeNow - maxRange * 1000);
};

export const dataChartConfig = (
  noiseLevels: NoiseLevels[] = [],
  newDevice: boolean = false,
  color: string = "blue",
  maxRange?: number
): ChartDataset => {
  const lastNoiseLevels = getFilteredNoiseLevels(noiseLevels, maxRange);
  return {
    data: lastNoiseLevels.map((n) => n.level),
    borderColor: color,
    backgroundColor: color,
    borderWidth: 2,
    fill: false,
    showLine: true,
    tension: 0.4, // Define la curvatura cúbica monótona
    pointRadius: 0, // Elimina los puntos del gráfico
    label: newDevice ? "dBA" : "",
  };
};
