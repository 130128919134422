import { DELETE_USER } from "./api-url.constants";
import { API_METHODS } from "../shared";
import { DeleteUserError } from "./errors/DeleteUserError ";
import { typedFetch } from "@/lib/helpers";

export const deleteUser = async (userId: string): Promise<boolean> => {
  const result = await typedFetch(`${DELETE_USER}/${userId}`, {
    method: API_METHODS.DELETE,
  });

  if (!result.ok) {
    throw new DeleteUserError();
  }

  return true;
};
