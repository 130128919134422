import React from "react";

import { CardProps } from "@mui/material";
import Card from "@mui/material/Card";

interface CardComponentProps extends CardProps {
  children: React.ReactNode;
}

export const CardComponent: React.FC<CardComponentProps> = ({ children, ...props }) => {
  return <Card {...props}>{children}</Card>;
};

export default CardComponent;
