import React from "react";

import { useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

interface SkeletonComponentProps {
  variant?: "rectangular" | "circular" | "text" | "rounded";
  width?: string | number;
  height?: string | number;
  style?: React.CSSProperties;
  animation?: "pulse" | "wave" | false;
  dataTestId?: string;
}

export const SkeletonComponent = ({
  variant = "text",
  animation = "wave",
  width,
  height,
  style = {},
  dataTestId,
}: SkeletonComponentProps) => {
  const theme = useTheme();
  return (
    <Skeleton
      data-testid={dataTestId}
      variant={variant}
      width={width}
      height={height}
      style={{
        background: theme.palette.grey[200],
        ...style,
      }}
      animation={animation}
    />
  );
};
