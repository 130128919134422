/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef, ReactNode } from "react";

import { BoxProps } from "@mui/material/Box";

import MDBoxRoot from "./MDBoxRoot";
import { MDBoxType } from "./model";

type MDBoxProps = Partial<MDBoxType> & {
  children: ReactNode;
  [key: string]: any;
  dataTestId?: string;
} & BoxProps;

const MDBox = forwardRef<unknown, MDBoxProps>(
  (
    {
      variant = "contained",
      bgColor = "transparent",
      color = "dark",
      opacity = 1,
      borderRadius = "none",
      shadow = "none",
      coloredShadow = "none",
      fullWidth = false,
      children,
      dataTestId,
      ...rest
    },
    ref
  ) => (
    <MDBoxRoot
      {...rest}
      ref={ref}
      data-testid={dataTestId}
      ownerState={{
        variant,
        bgColor,
        color,
        opacity,
        borderRadius,
        shadow,
        coloredShadow,
        fullWidth,
      }}
    >
      {children}
    </MDBoxRoot>
  )
);

export default MDBox;
