import React from "react";

import MDBox from "../../md-box";
import MDTypography from "../../md-typography";
import { TooltipComponent } from "../../tooltip";
import { CardBodyProps } from "../card-line-chart.models";

export const CardLineChartCountAndPercentageComponent = ({
  count,
  percentage,
  tooltip,
}: CardBodyProps) => (
  <MDBox display="flex" minHeight="1.375em" width="100%" alignItems="center">
    {count?.label && (
      <>
        <MDBox display="flex">
          <MDTypography variant="h5" fontWeight="bold" color={count.color || "dark"}>
            {count.label}&nbsp;
          </MDTypography>
        </MDBox>
        {percentage?.label && (
          <TooltipComponent arrow={false} placement={"bottom"} title={tooltip}>
            <MDBox display="flex" width="100%">
              <MDTypography
                variant="button"
                fontWeight="bold"
                color={percentage.color || "dark"}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginRight: "4.5em",
                }}
              >
                {percentage.label}
              </MDTypography>
            </MDBox>
          </TooltipComponent>
        )}
      </>
    )}
  </MDBox>
);
