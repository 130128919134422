import { UPDATE_USER } from "./api-url.constants";
import { UpdateUserApiDto } from "./dtos";
import { API_METHODS } from "../shared";
import { UpdateUserError } from "./errors/UpdateUserError";
import { typedFetch } from "@/lib/helpers";

export const updateUser = async (user: UpdateUserApiDto): Promise<boolean> => {
  const { _id, ...body } = user;

  const result = await typedFetch(`${UPDATE_USER}/${_id}`, {
    method: API_METHODS.PUT,
    body: JSON.stringify(body),
  });

  if (!result.ok) {
    throw new UpdateUserError();
  }

  return true;
};
