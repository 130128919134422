import React from "react";

import Icon from "@mui/material/Icon";

import MDBox from "../../md-box";
import MDTypography from "../../md-typography";
import { CardHeaderProps } from "../card-line-chart.models";

export const CardLineChartTitleComponent = ({ title, icon }: CardHeaderProps) => (
  <MDBox display="flex" alignItems="center" minHeight="1.05em" justifyContent="space-between">
    {title && (
      <MDTypography
        variant="button"
        textTransform="capitalize"
        fontWeight="medium"
        color="text"
        noWrap
      >
        {title}
      </MDTypography>
    )}
    {icon && (
      <Icon fontSize="medium" color="disabled" sx={{ marginRight: 1 }}>
        {icon}
      </Icon>
    )}
  </MDBox>
);
