import { RoomUsedLicense } from "../../../../models/room";
import { RoomUsedLicenseDTO, RoomUsedLicensesApiDTO } from "../dtos";

export const mapRoomUsedLicensesDTOtoRoomUsedLicenses = (
  rooms: RoomUsedLicensesApiDTO["data"]
): RoomUsedLicense[] => {
  return rooms.usedLicenses.map(mapRoomUsedLicense);
};

const mapRoomUsedLicense = (room: RoomUsedLicenseDTO): RoomUsedLicense => {
  return {
    _id: room._id,
    name: room.name,
    licenseId: room.licenseId,
    serviceContractId: room.serviceContractId,
  };
};
