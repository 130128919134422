import { ServiceContract } from "../../../../models";
import { CustomerServiceContractsDTO } from "../dtos";

export const mapCustomerServiceContractsDTOtoCustomerServiceContracts = (
  customerServiceContractsApiDTO: CustomerServiceContractsDTO["data"]
): ServiceContract[] => {
  return customerServiceContractsApiDTO.serviceContracts.map((serviceContractDTO) => {
    return {
      id: serviceContractDTO.id,
      licenses: serviceContractDTO.licenses.map((license) => ({
        id: license.id,
        name: license.name,
        qty: license.qty,
        activo: license.activo,
        family: license.family ?? "",
      })),
    };
  });
};
