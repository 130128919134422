import React, { forwardRef } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";

import { InputPasswordStyled } from "./input-password.styled";
import MDBox from "../md-box";

export type InputPasswordProps = {
  value: string;
  onChange: (value: string) => void;
  dataTestId?: string;
  id: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  errorMessage?: string;
  success?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  allowErrors?: boolean;
  placeholder?: string;
  errorMessageDataTestId?: string;
  label?: string;
};
export const InputPasswordComponent = forwardRef<HTMLDivElement, InputPasswordProps>(
  (
    {
      value,
      id,
      required,
      dataTestId,
      fullWidth,
      onChange,
      placeholder,
      errorMessage = "",
      error = false,
      success = false,
      disabled = false,
      allowErrors = true,
      errorMessageDataTestId,
      label,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    return (
      <FormControl fullWidth={fullWidth} variant="outlined">
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        <InputPasswordStyled
          placeholder={placeholder}
          label={label}
          value={value}
          fullWidth={fullWidth}
          id={id}
          onChange={(e) => onChange(e.target.value)}
          required={required}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowPassword}
                onMouseDown={handleMouseDownPassword}
                data-testid="toggle-password-visibility"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          ownerState={{ error, success, disabled }}
          ref={ref}
          inputProps={{ autoComplete: "new-password", "data-testid": dataTestId }}
        />
        {allowErrors && (
          <MDBox minHeight="2.5ch">
            {error && errorMessage && (
              <p
                data-testid={errorMessageDataTestId}
                style={{ color: "red", fontSize: "1ch", paddingTop: "1ch" }}
              >
                {errorMessage}
              </p>
            )}
          </MDBox>
        )}
      </FormControl>
    );
  }
);
