import { SxProps, Theme } from "@mui/material";

import { KeyValue } from "@/models";

export type SearchFunction = (search: string) => void;

export type ChangeFunction = (value: KeyValue | null) => void;

export type LimitFunction = (limit?: number) => void;

export interface SelectSearchPaginatedComponentProps {
  name: string;
  noOptionsText: string;
  menuItems: KeyValue[];
  hasMore: boolean;
  loading: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  searchFunction: SearchFunction;
  onChange: ChangeFunction;
  value: KeyValue | null;
  placeholder?: string;
  sx?: SxProps<Theme>;
  limitFunction: LimitFunction;
}

export const DEFAULT_LIMIT = 10;
