import { GET_ENVIRONMENT_BETWEEN_RANGE_BY_MONITOR_ID } from "./api-url.constants";
import { GetEnvironmentBetweenRangeByMonitorIdResponseApiDto } from "./dtos";
import { mapperEnvironmentsDTOToMonitors } from "./mappers";
import { jsonFetch } from "@/lib/helpers";
import { Environment } from "@/models";

interface GetEnvironmentBetweenRangeByMonitorIdParams {
  monitorId: string;
  from: number;
  to: number;
}

export const getEnvironmentBetweenRangeByMonitorId = async ({
  monitorId,
  from,
  to,
}: GetEnvironmentBetweenRangeByMonitorIdParams): Promise<Environment[]> => {
  const environmentsDTO = await jsonFetch<GetEnvironmentBetweenRangeByMonitorIdResponseApiDto>(
    `${GET_ENVIRONMENT_BETWEEN_RANGE_BY_MONITOR_ID}/${encodeURIComponent(monitorId)}?from=${from}&to=${to}`
  );
  return mapperEnvironmentsDTOToMonitors(environmentsDTO);
};
