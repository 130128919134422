import { EnvironmentDTO, GetEnvironmentBetweenRangeByMonitorIdResponseApiDto } from "../dtos";
import { orderDataByCreatedAt } from "../utils";
import { Environment } from "@/models";

const mapperEnvironmentDTOToEnvironment = (environmentDTO: EnvironmentDTO): Environment => {
  return {
    createdAt: environmentDTO.createdAt,
    temperature: environmentDTO.temperature,
    humidity: environmentDTO.humidity,
    iaq: environmentDTO.iaq,
    voc: environmentDTO.voc,
  };
};

export const mapperEnvironmentsDTOToMonitors = (
  environmentDTO: GetEnvironmentBetweenRangeByMonitorIdResponseApiDto
): Environment[] => {
  const environments = environmentDTO.data.map(mapperEnvironmentDTOToEnvironment);
  return orderDataByCreatedAt(environments);
};
