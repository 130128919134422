import React from "react";

import Tooltip from "@mui/material/Tooltip";

interface TooltipComponentProps {
  title: React.ReactNode;
  children: React.ReactElement;
  arrow?: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

export const TooltipComponent: React.FC<TooltipComponentProps> = ({
  title,
  placement = "bottom-end",
  children,
  arrow,
}) => {
  return (
    <Tooltip title={title} placement={placement} arrow={arrow}>
      <span style={{ width: "100%" }}>{children}</span>
    </Tooltip>
  );
};
