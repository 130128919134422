import * as React from "react";

// eslint-disable-next-line import/named
import Icon, { IconProps } from "@mui/material/Icon";

type IconComponentProps = {
  model: string;
  className?: string;
  iconProps?: IconProps;
  style?: React.CSSProperties;
  fontSize?: "inherit" | "large" | "medium" | "small";
};
export const IconComponent: React.FC<IconComponentProps> = ({
  model,
  iconProps,
  className,
  style,
  fontSize,
}) => {
  return (
    <Icon {...iconProps} className={className} style={style} fontSize={fontSize}>
      {model}
    </Icon>
  );
};
