import React from "react";

import { SxProps } from "@mui/material";
import Icon from "@mui/material/Icon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Link } from "react-router-dom";

import { TabItem } from "./tab.model";

interface TabComponentProps {
  items: TabItem[];
  initialValue?: number;
  sxStyle?: SxProps;
  handleChangeRoute: (href: string) => void;
}
export const TabComponent = ({
  items,
  initialValue = 0,
  sxStyle,
  handleChangeRoute,
}: TabComponentProps) => {
  const [selectedTab, setSelectedTab] = React.useState<number>(initialValue);
  const handleChange = (value: number) => {
    setSelectedTab(value);
    handleChangeRoute(items[value].href);
  };
  return (
    <Tabs
      orientation={"horizontal"}
      value={selectedTab}
      onChange={(e, value) => handleChange(value)}
    >
      {items.map((item, index) => (
        <Tab
          key={index}
          label={item.label}
          icon={item.icon && <Icon fontSize="small">{item.icon}</Icon>}
          sx={sxStyle}
          LinkComponent={Link}
        />
      ))}
    </Tabs>
  );
};
