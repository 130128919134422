import { NoiseThreshold } from "../../../../models/noise-threshold";
import { NoiseThresholdsApiDTO } from "../dtos/noise-thresholds.dto";

export const mapNoiseThresholdsApiDTOtoNoiseThresholds = (
  noiseThresholdsApiDTO: NoiseThresholdsApiDTO["data"]
): NoiseThreshold[] => {
  return noiseThresholdsApiDTO.noiseThresholds.map((noiseThresholdDTO) => ({
    _id: noiseThresholdDTO._id,
    name: noiseThresholdDTO.name,
    low: noiseThresholdDTO.low,
    medium: noiseThresholdDTO.medium,
    high: noiseThresholdDTO.high,
    description: noiseThresholdDTO.description,
    default: noiseThresholdDTO.default,
  }));
};
