export const convertTimestampToTimeString = (
  timestamp: number,
  locale?: Intl.LocalesArgument
): string => {
  return new Date(timestamp).toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};
