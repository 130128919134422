import { useMemo } from "react";
import React from "react";

import "chart.js/auto";
import { Chart as ChartJS, Plugin } from "chart.js";
import { ChartType } from "chart.js/auto";
import { Chart } from "react-chartjs-2";

import { dataChartConfig } from "./configs/noise-levels-chart-data.config";
import { labelsChartConfig } from "./configs/noise-levels-chart-labels.config";
import { limitsChartConfig } from "./configs/noise-levels-chart-limits.config";
import { useInternationalization } from "@/lib/contexts";
import { NoiseLevels, RoomDetails } from "@/models/booking-details";
export type NoiseLevelsChartProps = {
  room?: RoomDetails;
  noise?: NoiseLevels[];
  height?: number;
  color?: string;
  maxRange?: number;
};

export const NoiseLevelsChart: React.FC<NoiseLevelsChartProps> = ({
  room,
  noise = [],
  height = 150,
  color = "blue",
  maxRange,
}: NoiseLevelsChartProps) => {
  const { t } = useInternationalization("components.noise-levels-chart");
  const dataChart = dataChartConfig(noise, room?.deviceFirm?.startsWith("5"), color, maxRange);
  const length = dataChart.data.length;
  const limitsChart = limitsChartConfig(room?.noiseSettings, length);
  const labelsChart = labelsChartConfig(noise, length);
  const data = {
    labels: labelsChart,
    datasets: [dataChart, ...limitsChart],
  };
  const customTextPlugin: Plugin<ChartType> = {
    id: "customText",
    afterDraw: (chart: ChartJS) => {
      if (chart.data.datasets[0].data.length === 0) {
        const ctx = chart.ctx;
        const { width, height } = chart;
        ctx.save();
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "20px Arial";
        ctx.fillStyle = "red";
        ctx.fillText(t("data-not-found"), width / 2, height / 2);
        ctx.restore();
      }
    },
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return useMemo(
    () => (
      <Chart
        type="line"
        data={data}
        options={options}
        height={height}
        plugins={[customTextPlugin]}
      />
    ),
    [noise, room]
  );
};
