import { GET_SMOKING_PROBABILITY } from "./api-url.constants";
import { SmokingProbabilityDto } from "./dtos";
import { jsonFetch } from "@/lib/helpers";

export const getSmokingProbabilityByRoomId = async (roomId: string): Promise<number> => {
  const smokingProbabilityOutput = await jsonFetch<SmokingProbabilityDto>(
    `${GET_SMOKING_PROBABILITY}/${encodeURIComponent(roomId)}`
  );
  return smokingProbabilityOutput.data.smokingProbability;
};
