import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useMediaQuery, useTheme } from "@mui/material";

import { ChartData, ChartColor, CardChartProps } from "./card-line-chart.models";
import { getChartOptions } from "./card-line-chart.options";
import gradientChartLine from "@/styles/themes/common/functions/gradientChartLine";

interface CardLineChartHookProps {
  labelName: string;
  color: ChartColor;
  data: CardChartProps["data"];
  options: CardChartProps["options"];
}

export const useCardLineChartComponent = ({
  labelName,
  data,
  color,
  options,
}: CardLineChartHookProps) => {
  const theme = useTheme();
  const chartRef = useRef<HTMLDivElement>(null);
  const isTouchDevice = useMediaQuery("(pointer: coarse)");

  const { labels, datasets } = useMemo<ChartData>(() => {
    return data.reduce<ChartData>(
      (acc, item) => {
        acc.labels.push(item.label);
        acc.datasets[0].data.push(item.value);
        return acc;
      },
      {
        labels: [],
        datasets: [
          {
            label: labelName,
            color: color,
            tension: 0.4,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: theme.palette[color].main,
            fill: true,
            maxBarThickness: 6,
            data: [],
          },
        ],
      }
    );
  }, [data, theme]);

  const createGradientDatasets = useCallback(() => {
    const canvas = chartRef.current?.querySelector("canvas");
    if (canvas) {
      return datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: gradientChartLine(canvas, theme.palette[dataset.color].main),
      }));
    }

    return datasets;
  }, [datasets, theme]);

  const [chartDatasets, setChartDatasets] = useState(() => createGradientDatasets());
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    setChartDatasets(createGradientDatasets());
  }, [createGradientDatasets]);

  const handleShowTooltip = (value: boolean) => {
    setShowTooltip(value);
  };

  const chartOptions = useMemo(
    () =>
      getChartOptions({
        ...options,
        showTooltip: !isTouchDevice || (isTouchDevice && showTooltip),
      }),
    [isTouchDevice, showTooltip]
  );

  const onShowTooltip = useCallback(() => handleShowTooltip(true), [handleShowTooltip]);
  const onHideTooltip = useCallback(() => handleShowTooltip(false), [handleShowTooltip]);

  return {
    chartRef,
    labels,
    chartDatasets,
    chartOptions,
    onShowTooltip,
    onHideTooltip,
  };
};
