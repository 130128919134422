import { GET_NOISE_THRESHOLDS } from "./api-url.constants";
import { NoiseThresholdsApiDTO } from "./dtos/noise-thresholds.dto";
import { mapNoiseThresholdsApiDTOtoNoiseThresholds } from "./mappers/noise-thresholds.mapper";
import { NoiseThreshold } from "../../../models/noise-threshold";
import { jsonFetch } from "@/lib/helpers";

export const getNoiseThresholds = async (): Promise<NoiseThreshold[]> => {
  const noiseThresholdsDTO = await jsonFetch<NoiseThresholdsApiDTO>(GET_NOISE_THRESHOLDS);
  return mapNoiseThresholdsApiDTOtoNoiseThresholds(noiseThresholdsDTO.data);
};
