import { GET_PAGINATED_ROOMS } from "./api-url.constants";
import { PaginatedRoomsDTO } from "./dtos";
import { mapPaginatedRoomsDTOtoPaginatedRooms } from "./mappers";
import { PaginatedRooms } from "../../../models/room";
import { jsonFetch } from "@/lib/helpers";

export const getPaginatedRooms = async (queryParams: string): Promise<PaginatedRooms> => {
  const paginatedRoomsDTO = await jsonFetch<PaginatedRoomsDTO>(
    `${GET_PAGINATED_ROOMS}${queryParams}`
  );
  return mapPaginatedRoomsDTOtoPaginatedRooms(paginatedRoomsDTO.data);
};
