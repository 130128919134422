import { GET_MONITORING_BETWEEN_RANGE_BY_MONITOR_ID } from "./api-url.constants";
import { GetMonitoringBetweenRangeByMonitorIdResponseApiDto } from "./dtos";
import { mapperMonitorsDTOToMonitors } from "./mappers";
import { jsonFetch } from "@/lib/helpers";
import { Monitor } from "@/models";

interface GetMonitoringBetweenRangeByMonitorIdParams {
  monitorId: string;
  from: number;
  to: number;
}

export const getMonitoringBetweenRangeByMonitorId = async ({
  monitorId,
  from,
  to,
}: GetMonitoringBetweenRangeByMonitorIdParams): Promise<Monitor[]> => {
  const monitorsDTO = await jsonFetch<GetMonitoringBetweenRangeByMonitorIdResponseApiDto>(
    `${GET_MONITORING_BETWEEN_RANGE_BY_MONITOR_ID}/${encodeURIComponent(monitorId)}?from=${from}&to=${to}`
  );
  return mapperMonitorsDTOToMonitors(monitorsDTO);
};
