import { getUpdateRoomSettingsUrl } from "./api-url.constants";
import { UpdateRoomSettingsApiDto } from "./dtos/update-room-settings.api.dto";
import { UpdateRoomError, UpdateRoomSalesforceError } from "./errors";
import { API_METHODS } from "../shared";
import {
  UPDATE_ROOM_ERROR_MESSAGE,
  UPDATE_ROOM_SALESFORCE_ERROR_MESSAGE,
} from "./errors/error-message.constants";
import { typedFetch } from "@/lib/helpers";

export const updateRoomSettings = async (bodyDTO: UpdateRoomSettingsApiDto): Promise<boolean> => {
  const { _id, ...body } = bodyDTO;
  const result = await typedFetch(getUpdateRoomSettingsUrl(_id), {
    method: API_METHODS.PUT,
    body: JSON.stringify(body),
  });

  if (result.ok) return true;

  const responseJson = await result.json();

  if ("error" in responseJson) {
    const message = Array.isArray(responseJson.message)
      ? responseJson.message[0]
      : responseJson.message;
    if (message === UPDATE_ROOM_SALESFORCE_ERROR_MESSAGE) throw new UpdateRoomSalesforceError();
    if (message === UPDATE_ROOM_ERROR_MESSAGE) throw new UpdateRoomError();
  }

  return true;
};
