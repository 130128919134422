import React from "react";

import Slider from "@mui/material/Slider";

import MDBox from "../md-box";
import MDTypography from "../md-typography";

interface SliderCompontentProps {
  value: number;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  label?: string;
  min?: number;
  max?: number;
  step?: number;
  onChange: (value: number) => void;
  onChangeCommitted?: () => void;
}

export const SliderComponent: React.FC<SliderCompontentProps> = ({
  label,
  value,
  min = 0,
  max = 100,
  step = 1,
  color = "info",
  onChange,
  onChangeCommitted,
}) => {
  return (
    <MDBox display="flex" flex={1}>
      {label && (
        <MDTypography type="button" gutterBottom>
          {label}
        </MDTypography>
      )}
      <Slider
        color={color}
        valueLabelDisplay="off"
        aria-label="slider"
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(_, newValue) => onChange(newValue as number)}
        onChangeCommitted={onChangeCommitted}
      />
    </MDBox>
  );
};
