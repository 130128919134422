import { Monitor } from "../../../../models";
import { GetMonitoringBetweenRangeByMonitorIdResponseApiDto, MonitorDTO } from "../dtos";
import { orderDataByCreatedAt } from "../utils";

const mapperMonitorDTOToMonitor = (monitorDTO: MonitorDTO): Monitor => {
  return {
    batteryLevel: monitorDTO.batteryLevel,
    batteryMode: monitorDTO.batteryMode,
    data: monitorDTO.data,
    level: monitorDTO.level,
    createdAt: monitorDTO.createdAt,
  };
};

export const mapperMonitorsDTOToMonitors = (
  monitorsDTO: GetMonitoringBetweenRangeByMonitorIdResponseApiDto
): Monitor[] => {
  const monitors = monitorsDTO.data.map(mapperMonitorDTOToMonitor);
  return orderDataByCreatedAt(monitors);
};
