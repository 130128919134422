import React from "react";

import TextField from "@mui/material/TextField";

import { useInternationalization } from "../../contexts";
import { IconComponent } from "../icons";
import MDBox from "../md-box";
import MDPagination from "../md-pagination";
import MDTypography from "../md-typography";

interface PaginationComponentProps {
  pageSize: number;
  pageOptions: number[];
  showTotalEntries: boolean;
  entriesStart: number;
  entriesEnd: number;
  totalEntries: number;
  canNextPage: boolean;
  currentPage: number;
  canPreviousPage: boolean;
  handlePageChange: (page: number, pageSize: number) => void;
  paginationStyle?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  canNextPage,
  canPreviousPage,
  currentPage,
  entriesEnd,
  entriesStart,
  handlePageChange,
  pageOptions,
  pageSize,
  showTotalEntries,
  totalEntries,
  paginationStyle,
}) => {
  const { getRootTranslation } = useInternationalization();

  return (
    <MDBox
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", sm: "center" }}
      p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
    >
      {showTotalEntries && (
        <MDBox mb={{ xs: 3, sm: 0 }}>
          <MDTypography variant="button" color="secondary" fontWeight="regular">
            {getRootTranslation("table.showing", { entriesStart, entriesEnd, totalEntries })}
          </MDTypography>
        </MDBox>
      )}

      {pageOptions.length > 1 && (
        <MDPagination
          variant={
            paginationStyle && paginationStyle.variant ? paginationStyle.variant : "gradient"
          }
          color={paginationStyle && paginationStyle.color ? paginationStyle.color : "info"}
        >
          {canPreviousPage && (
            <MDPagination item onClick={() => handlePageChange(currentPage - 1, pageSize)}>
              <IconComponent model="chevron_left" style={{ fontWeight: "bold" }}></IconComponent>
            </MDPagination>
          )}
          {pageOptions.length > 6 ? (
            <MDBox width="5rem" mx={1}>
              <TextField
                type="number"
                inputProps={{
                  min: 1,
                  max: pageOptions.length,
                  "data-testid": "data-table-page-input",
                }}
                value={String(currentPage + 1)}
                onChange={({ target: { value } }) => {
                  const numberValue = Number(value);
                  const checkedValue =
                    !isNaN(numberValue) && numberValue - 1 > 0 ? numberValue - 1 : 0;
                  const calculatedValue =
                    checkedValue > pageOptions.length - 1 ? pageOptions.length - 1 : checkedValue;
                  handlePageChange(calculatedValue, pageSize);
                }}
              />
            </MDBox>
          ) : (
            pageOptions.map((option: number) => (
              <MDPagination
                item
                key={option}
                onClick={() => {
                  handlePageChange(option, pageSize);
                }}
                dataTestId={`data-table-page-${option + 1}${currentPage === option ? "-active" : ""}`}
                active={currentPage === option}
              >
                {option + 1}
              </MDPagination>
            ))
          )}

          {canNextPage && (
            <MDPagination
              item
              onClick={() => handlePageChange(currentPage + 1, pageSize)}
              dataTestId={"data-table-next-page-btn"}
            >
              <IconComponent model="chevron_right" style={{ fontWeight: "bold" }}></IconComponent>
            </MDPagination>
          )}
        </MDPagination>
      )}
    </MDBox>
  );
};
