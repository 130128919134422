import { GET_ROOM_URL } from "./api-url.constants";
import { RoomApiDTO } from "./dtos/room.api.dto";
import { mapRoomDTO } from "./mappers";
import { Room } from "../../../models";
import { jsonFetch } from "@/lib/helpers";

export const getRoomById = async (roomId: string): Promise<Room> => {
  const roomDetailsApiDTO = await jsonFetch<RoomApiDTO>(`${GET_ROOM_URL}/${roomId}`);
  return mapRoomDTO(roomDetailsApiDTO.data);
};
