import { getRoomUsedLicensesUrl } from "./api-url.constants";
import { RoomUsedLicensesApiDTO } from "./dtos";
import { mapRoomUsedLicensesDTOtoRoomUsedLicenses } from "./mappers";
import { RoomUsedLicense } from "../../../models/room";
import { jsonFetch } from "@/lib/helpers";

export const getRoomUsedLicenses = async (userId: string): Promise<RoomUsedLicense[]> => {
  const roomUsedLicensesApiDTO = await jsonFetch<RoomUsedLicensesApiDTO>(
    getRoomUsedLicensesUrl(userId)
  );
  return mapRoomUsedLicensesDTOtoRoomUsedLicenses(roomUsedLicensesApiDTO.data);
};
